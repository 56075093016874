@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
  --green: #f75253;

}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  display: none; /* Optional: just make scrollbar invisible */
}
img {
  pointer-events: none;
  -webkit-touch-callout: none;
}
body {
  margin: 0;
  
}
:root {
  --main: rgb(68, 205, 74);
  --gray: #f3f5f7;
  --secendary: #46a6ea;
  --white: #ffffff;
  --black: #000000;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .app {
  display: flex;
  width: 100vw;
  height: 95vh;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  background-color: gray;
  scroll-behavior: unset;
  position: fixed;
} */
.layout {
  overflow: hidden;
  height: 100vh;
  width: 100%;
  max-width: 480px;
  background-color: white;
  scroll-behavior: smooth;
  position: relative;
  border-radius: 10px;
}
.mainrow {
  position: relative;
}
.container_anime {
  height: 100vw;
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100vw;
  max-width: 500px;
}
.container_main {
  width: calc(100vw - 64px);
  overflow: hidden;
  position: absolute;
  top: 0px;
  height: fit-content;
  max-width: 500px;
}
.mainheader {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0.1rem;
  justify-content: center;
  align-items: center;
}
.heading {
  color: #000000;
  font-family: "Rubik", sans-serif;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  width: 100%;
  text-align: center;
}
.page-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-evenly;
  height: calc(100% - 35px);
}
.page-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}
.clear_Logout_button,
.simple_Logout_button {
  border: none;
  color: #fff;

  width: 100px;
  padding: 10px 0px;
  margin: 2px 20px;
  border-radius: 1.5rem;
  cursor: pointer;
  position: relative;
  letter-spacing: 1px;
  font-weight: 500;
  white-space: nowrap;
  /* box-shadow: 3px 4px 3px black; */
  transition: all 0.3s ease-in-out;
}
.clear_Logout_button:hover,
.simple_Logout_button:hover {
  transform: scale(1.05);
}
.form {
  display: flex;
  flex-direction: column;
  /* gap: 1.2rem; */
  justify-content: space-between;
  height: 100%;
  height: fit-content;
  overflow: hidden;
}
.input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 90%;
  gap: 8px;
  background: transparent;
}

 .input-container input {
  background: var(--gray);
  /* border: none;
  border-bottom: 2px solid #000; */
  color: #000;
  font-size: 18px;
}

 .input-container input:-internal-autofill-selected {
  background: black;
  border: none;
  border-bottom: 2px solid #fff;
  /* color: #fff; */
  font-size: 18px;
}

.input-container > label {
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: 1.5px;
  font-weight: 600;
}

.submit-btn {
  padding: 10px 3rem;
  background: var(--main);
  border: 2px solid var(--main);
  border-radius: 1rem;
  width: fit-content;
  min-width: 10rem;
  font-weight: bold;
  font-size: 18px;
  color: white;
  letter-spacing: 1.5px;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 100%;
}
.submit-btn:hover {
  border: 2px solid white;
  background: var(--white);
  color: var(--main);
  scale: 1.05;
}

.submit-btn:hover svg {
  color: var(--main);
}

.input-container > input {
  padding: 10px;
 

  font-size: 20px;
  max-width: 300px;
  letter-spacing: 1px;
  outline: none;
  border: none;
  border-bottom: 2px solid white;
}

.input-container > input:focus {
  outline: none;
}
.otp-field {
  width: 28px;
  margin-right: 10px;
  padding-left: 8px;
  height: 30px;

}
.otp-field:focus{
  border-color: var(--main) !important;
}